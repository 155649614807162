<template>
  <div id="cardano-pairs" class="cardano-pairs">
    <div class="card mb-3">
      <div class="card-body">
        <div class="d-flex justify-content-between align-items-center">
          <div>
            <h2 class="h4 mb-0 font-size-125 font-family-krona">Cardano Pairs</h2>
          </div>
          <div>
            <a href="">More<span class="ml-2"><font-awesome-icon :icon="['fas', 'chevron-right']"/></span></a>
          </div>
        </div>
      </div>
    </div>
    <div class="card">
      <div class="card-body p-0">
        <table class="table mb-0">
          <tbody>
            <template v-for="(token, index) in tokenList" v-bind:key="index">
              <tr class="tr-clickable" v-if="index < 10" @click="goToDetail(token)" >
                <td class="white-space-nowrap px-2" :class="[{'border-0': index == 0}]">
                  <span class="mr-2">#{{ index + 1 }}</span>
                
                  <span v-if="token.assetA_data && token.assetA_data.onchain_metadata && token.assetA_data.onchain_metadata.symbol">{{ token.assetA_data.onchain_metadata.symbol }}</span>/<span v-if="token.assetB_data && token.assetB_data.onchain_metadata && token.assetB_data.onchain_metadata.symbol" class="text-muted font-size-075">{{ token.assetB_data.onchain_metadata.symbol }}</span>
                </td>
                <td class="font-size-080 px-2" :class="[{'border-0': index == 0}]">
                  <span v-if="token.assetAB_price" v-html="$fixPrice(token.assetAB_price,'4')"></span>
                </td>
              </tr>
            </template>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>

import axios from 'axios';

export default {
  name: 'Home',
  data() {
    return {
      tokenList: '',
    };
  },
  created() {
    axios.get(`/v2/tokens/cardano`)
      .then(async (response)=> {
        this.tokenList = response.data.body;
        console.log(this.tokenList);
        this.$emit('widgetLoaded');
      }); 
  },
  methods: {
    goToDetail(crypto){
      this.$router.push({ name: 'crypto-detail', params: { address: crypto.mainToken.address, chain: 'bsc' }, query: { symbol: crypto.mainToken.symbol}});
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">

</style>
